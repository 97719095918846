/**
 * Force cover image for certain subcategories
 * @param {Array} subCategoryNames - Array of subcategories
 * @returns {boolean} - True if the subcategory should force a cover image, false otherwise
 */
export function forceCoverImageForSubcategory(subCategoryNames) {
  return subCategoryNames?.some((name) =>
    /hat|bag|drinkware|padfolio|notebook/i.test(name)
  );
}
