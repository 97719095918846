/**
 * Utility functions for building product card image URLs and srcsets
 */
import {IMAGE_PIXEL_DENSITY_DPRS} from './algolia_constants';
import {
  mmsImageFingerprint,
  getColorImage,
  overrideUrlParamsForYDH
} from '../../utils/images';
import {getIxParamsForCard} from '../../utils/design/ixParams';

// Function-specific caches with size limits
const srcSetCache = new Map();
const colorUrlsCache = new Map();
const MAX_SRCSET_CACHE = 100;
const MAX_COLOR_URLS_CACHE = 50;

/**
 * Manages a cache with size limit and eviction policy
 * @param {Map} cache - The cache Map object
 * @param {number} maxSize - Maximum cache size
 * @param {number} evictionCount - Number of items to evict when full
 * @param {string} key - Cache key
 * @param {*} value - Value to cache
 */
function manageCache(cache, maxSize, evictionCount, key, value) {
  if (cache.size >= maxSize) {
    const keysToDelete = Array.from(cache.keys()).slice(0, evictionCount);
    keysToDelete.forEach((k) => cache.delete(k));
  }
  cache.set(key, value);
}

/**
 * Main entry point: builds a product image with src and srcset
 */
export function buildProductImage(options) {
  const {
    hit,
    categoryId,
    siteWideSearch = false,
    selectedColorId = null,
    noMoreLaydowns = false,
    iframer = false,
    ydhSettings = {overrides: {}, default: {}, nonUrlQueryParams: []},
    cid,
    firstImage,
    isFirstHit = false
  } = options;

  // Handle first hit with custom image
  if (isFirstHit && firstImage) {
    return {
      src: firstImage,
      srcset: generateSrcSet(firstImage)
    };
  }

  // Get primary image URL
  const primaryImageUrl = getPrimaryImageUrl({
    hit,
    categoryId,
    siteWideSearch,
    noMoreLaydowns,
    iframer,
    ydhSettings,
    cid
  });

  // Handle case with no selected color
  if (!selectedColorId) {
    return {
      src: primaryImageUrl,
      srcset: generateSrcSet(primaryImageUrl)
    };
  }

  // Get selected color image URL
  const selectedColorImageUrl = getSelectedColorImageUrl({
    hit,
    selectedColorId,
    categoryId,
    siteWideSearch,
    noMoreLaydowns,
    iframer,
    ydhSettings,
    cid
  });

  const src = selectedColorImageUrl || primaryImageUrl;
  return {
    src,
    srcset: generateSrcSet(src)
  };
}

/**
 * Gets image URL for the selected color
 */
function getSelectedColorImageUrl({
  hit,
  selectedColorId,
  categoryId,
  siteWideSearch,
  noMoreLaydowns,
  iframer,
  ydhSettings,
  cid
}) {
  if (!selectedColorId || !hit.colors?.length) return null;

  const selectedColorIdInt = parseInt(selectedColorId);

  // Create cache key
  const cacheKey = JSON.stringify({
    productId: hit.product_id,
    colorId: selectedColorIdInt,
    categoryId,
    siteWideSearch,
    noMoreLaydowns,
    iframer,
    hasYdhOverrides: !!ydhSettings.overrides?.[parseInt(hit.product_id)]
  });

  // Return cached value if exists
  if (colorUrlsCache.has(cacheKey)) {
    return colorUrlsCache.get(cacheKey);
  }

  // Find the color object
  const selectedColor = hit.colors.find(
    (color) => color.id === selectedColorIdInt
  );
  if (!selectedColor) return null;

  // Get image URL
  const imageUrl = getColorImageUrl({
    color: selectedColor,
    hit,
    categoryId,
    siteWideSearch,
    noMoreLaydowns,
    iframer,
    ydhSettings,
    cid
  });

  // Manage cache
  manageCache(colorUrlsCache, MAX_COLOR_URLS_CACHE, 10, cacheKey, imageUrl);

  return imageUrl;
}

/**
 * Gets the URL for a specific color image
 */
function getColorImageUrl({
  color,
  hit,
  categoryId,
  siteWideSearch,
  noMoreLaydowns,
  iframer,
  ydhSettings,
  cid
}) {
  // Check for YDH settings
  const ydhCustomSettings = ydhSettings.overrides?.[parseInt(hit.product_id)];
  if (ydhCustomSettings) {
    return getYdhImageUrl(color, hit, ydhCustomSettings, ydhSettings);
  }

  // Handle category or site-wide search
  if (categoryId || siteWideSearch) {
    return getCategoryOrSiteWideImageUrl({
      color,
      hit,
      categoryId,
      siteWideSearch,
      noMoreLaydowns,
      iframer,
      cid
    });
  }

  // Default to basic color image URL
  return color.image_url;
}

/**
 * Gets URL with YDH (Your Design Here) settings
 */
function getYdhImageUrl(color, hit, ydhCustomSettings, ydhSettings) {
  const ydhGeneralSettings = {
    defaultSettings: ydhSettings.default,
    nonUrlQueryParams: ydhSettings.nonUrlQueryParams
  };

  // Determine design to use
  const hasColorSpecificDesign =
    hit.colors &&
    hit.colors.map((c) => c.id)?.includes(color.id) &&
    ydhCustomSettings.colors &&
    ydhCustomSettings.colors[color.id];

  const design = hasColorSpecificDesign
    ? ydhCustomSettings.colors[color.id]?.design
    : ydhCustomSettings?.design;

  return overrideUrlParamsForYDH(
    color.image_url,
    hit,
    design,
    ydhCustomSettings,
    ydhGeneralSettings
  );
}

/**
 * Gets URL for category or site-wide search
 */
function getCategoryOrSiteWideImageUrl({
  color,
  hit,
  categoryId,
  siteWideSearch,
  noMoreLaydowns,
  iframer,
  cid
}) {
  const catId = parseInt(categoryId) || hit.primary_category_id;
  const ixParams = getIxParamsForCard(
    hit,
    color.image_url,
    catId,
    iframer,
    cid
  );

  const categoryImage = hit?.category_images?.find((cat) => cat.id === catId);
  const hitWithCategory = {
    ...hit,
    ...(categoryId ? {category_id: categoryId} : {})
  };

  return getColorImage(
    color.id,
    hitWithCategory,
    mmsImageFingerprint(hit),
    ixParams,
    noMoreLaydowns,
    iframer,
    categoryImage?.lab_image_eligible,
    false
  );
}

/**
 * Determines which image strategy to use and returns the primary image URL
 */
function getPrimaryImageUrl({
  hit,
  categoryId,
  siteWideSearch,
  noMoreLaydowns,
  iframer,
  ydhSettings,
  cid
}) {
  const categoryIdInt = parseInt(categoryId);
  const hasYdhOverrides = !!ydhSettings.overrides?.[parseInt(hit.product_id)];

  // Use appropriate strategy based on context
  if (categoryId && !siteWideSearch) {
    return getCategorySpecificImage({
      hit,
      categoryIdInt,
      noMoreLaydowns,
      iframer,
      cid,
      siteWideSearch
    });
  }

  if (siteWideSearch && !hasYdhOverrides) {
    return getSiteWideSearchImage({
      hit,
      categoryIdInt,
      noMoreLaydowns,
      iframer,
      cid
    });
  }

  return getDefaultStyleCardImage({
    hit,
    categoryIdInt,
    siteWideSearch,
    noMoreLaydowns,
    iframer,
    ydhSettings,
    cid
  });
}

/**
 * Gets a category-specific image
 */
function getCategorySpecificImage({
  hit,
  categoryIdInt,
  noMoreLaydowns,
  iframer,
  siteWideSearch
}) {
  const categoryImage = hit?.category_images?.find(
    (cat) => cat.id === categoryIdInt
  );

  // Fast path for non-eligible images
  if (categoryImage && !categoryImage.lab_image_eligible) {
    return hit.style_card_image_url;
  }

  // Find appropriate color ID
  const colorId = findPreferredColorId(hit, categoryImage) || hit.product_id;

  // Get the best image URL
  const imageUrl =
    catalogImageUrl(hit, categoryImage) ||
    categoryImage?.image_url ||
    hit.style_card_image_url;

  return getColorImage(
    colorId,
    hit,
    mmsImageFingerprint(hit),
    getIxParamsForCard(hit, imageUrl, categoryIdInt, iframer),
    noMoreLaydowns,
    iframer,
    categoryImage?.lab_image_eligible,
    siteWideSearch
  );
}

/**
 * Helper to find the preferred color ID
 */
function findPreferredColorId(hit, categoryImage) {
  if (!categoryImage?.preferred_color_id || !hit.colors) return null;

  const matchingColor = hit.colors.find(
    (col) => col.id === categoryImage.preferred_color_id
  );

  return matchingColor ? matchingColor.id : null;
}

/**
 * Gets an image URL for site-wide search results
 */
function getSiteWideSearchImage({hit, categoryIdInt, noMoreLaydowns, iframer}) {
  const primaryCategoryId = hit.primary_category_id;
  if (!primaryCategoryId) return hit.style_card_image_url;

  const categoryImage = hit?.category_images?.find(
    (cat) => cat.id === primaryCategoryId
  );

  // Find preferred color
  const colorId = findPreferredColorId(hit, categoryImage) || hit.product_id;

  // Get the best image URL
  const imageUrl =
    catalogImageUrl(hit, categoryImage) ||
    categoryImage?.image_url ||
    hit.style_card_image_url;

  return getColorImage(
    colorId,
    hit,
    mmsImageFingerprint(hit),
    getIxParamsForCard(
      hit,
      imageUrl,
      primaryCategoryId || categoryIdInt,
      iframer
    ),
    noMoreLaydowns,
    iframer,
    categoryImage?.lab_image_eligible,
    false
  );
}

/**
 * Gets the default style card image URL
 */
function getDefaultStyleCardImage({
  hit,
  categoryIdInt,
  siteWideSearch,
  noMoreLaydowns,
  iframer,
  ydhSettings
}) {
  let cardImageUrl = hit.style_card_image_url;
  let colorId = hit.product_id;
  let labImageEligible = false;

  // Site-wide search with no YDH overrides
  if (siteWideSearch && !ydhSettings.overrides?.[parseInt(hit.product_id)]) {
    const primaryCategoryId = hit.primary_category_id;
    if (primaryCategoryId) {
      const primaryCategoryImage = hit?.category_images?.find(
        (cat) => cat.id === primaryCategoryId
      );

      if (primaryCategoryImage) {
        // Update parameters based on category image
        const preferredColor = hit?.colors?.find(
          (col) => primaryCategoryImage.preferred_color_id === col.id
        );

        if (preferredColor) colorId = preferredColor.id;
        labImageEligible = primaryCategoryImage.lab_image_eligible;
        cardImageUrl = primaryCategoryImage.image_url || cardImageUrl;
      }
    }
  }

  return getColorImage(
    colorId,
    hit,
    mmsImageFingerprint(hit),
    getIxParamsForCard(hit, cardImageUrl, categoryIdInt, iframer),
    noMoreLaydowns,
    iframer,
    labImageEligible,
    siteWideSearch
  );
}

/**
 * Helper function to get a catalog image URL
 */
function catalogImageUrl(hit, categoryImage) {
  if (!categoryImage?.lab_image_eligible) return undefined;

  const color = hit?.colors?.find(
    (col) => categoryImage.preferred_color_id === col.id
  );

  if (!color?.image_url) return undefined;

  return getColorImage(
    color.id,
    hit,
    mmsImageFingerprint(hit),
    getIxParamsForCard(hit, color.image_url, categoryImage.id, false),
    false,
    false,
    true,
    false
  );
}

/**
 * Generates a srcset attribute string for responsive images
 */
function generateSrcSet(imageUrl) {
  if (!imageUrl) return '';

  // Return cached result if available
  if (srcSetCache.has(imageUrl)) {
    return srcSetCache.get(imageUrl);
  }

  // Generate srcset string
  const result = Object.keys(IMAGE_PIXEL_DENSITY_DPRS)
    .map((key) => `${imageUrl} ${key}`)
    .join(', ');

  // Manage cache
  manageCache(srcSetCache, MAX_SRCSET_CACHE, 20, imageUrl, result);

  return result;
}
